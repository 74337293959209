import {React, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Layout/default";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import CustomModal from "../../components/Modal/Confirmation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Warehouse() {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const backUp = () => {
        toast.success("Backup Successfully");
    };

    const edit = () => {
        toast.info("You can edit now");
        setTimeout(() => {
            navigate('/company/add');
        }, 1000);
    };

    const login = () => {
        toast.success("Login Successfully");
        setTimeout(() => {
            navigate('/');
        }, 1000);
    }

    
    const deleteRow = () => {
        toast.error("Deleted successfully");
    }

    
    return(
        <Layout>
            <div className="container">
                <div className="row g-2 mt-2">
                    <div className="bg-white border rounded p-3">
                        <div className="text-end">
                            <Link to="/warehouses/add" className="btn btn-primary">+ New Warehouse</Link>
                        </div>
                        <div class="table-responsive mt-2">
                            <table class="table table-bordered table-hover">
                                <thead class="">
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Location</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Pos</td>
                                        <td>9 Number</td>
                                        <td className="text-center">
                                            <FontAwesomeIcon onClick={() => edit()} className="ms-2 text-info cursor-pointer" icon={faPen} />
                                            <FontAwesomeIcon onClick={() => deleteRow()} className="ms-2 text-danger cursor-pointer" icon={faTrashCan} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 text-center py-5"> 
                        <div>
                            <img src="https://platform.rewaatech.com/assets/images/empty-product.svg" />
                        </div>
                        <div className="py-4">
                            <div>You haven't added any warehouse yet</div>
                        </div>
                        <div>
                            <Link to="/warehouses/add" className="btn btn-primary">+ Add Warehouse</Link>
                        </div>
                    </div>
                </div>
                <CustomModal
                    showModal={showModal}
                    closeModal={closeModal}
                />
            </div>
            <ToastContainer />
        </Layout>
    )
}

export default Warehouse;