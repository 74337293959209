import React from "react";
import Layout from "../Layout/default";

function PaymentMethodAdd() {
    return(
        <Layout>
            <div>Payment Method Add</div>
        </Layout>
    )
}

export default PaymentMethodAdd;