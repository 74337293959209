import React from "react";
import Layout from "../Layout/default";

function WarehouseAdd() {
    return(
        <Layout>
            <div>Warehouse Add</div>
        </Layout>
    )
}

export default WarehouseAdd;