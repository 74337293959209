import React from "react";
import Layout from "../../Layout/default";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";

function StockCount() {
    return(
        <Layout>
            <div className="container">
                <div className="row g-2 mt-2">
                    <div className="col-12">
                        <div className="bg-white border rounded p-3">
                            <button className="btn btn-primary float-end">+ New Stock Count</button>
                            <div className="fw-bold mt-2">Stock Count</div>
                            <div class="table-responsive mt-2">
                                <table class="table table-bordered table-hover mt-2">
                                    <thead class="">
                                        <tr>
                                            <th>Count Number</th>
                                            <th>Count Name</th>
                                            <th>Issue Date</th>
                                            <th>Location</th>
                                            <th>Total Cost</th>
                                            <th>Clear</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        [1,1,1,1,1,1,1,1,1,1,1,1].map((item, index) => (
                                        <tr>
                                            <td>204</td>
                                            <td>Zahidz</td>
                                            <td>07/07/2024</td>
                                            <td>City</td>
                                            <td>100</td>
                                            <td>Yes</td>
                                            <td className="text-center">
                                                <FontAwesomeIcon className="ms-2 text-info cursor-pointer" icon={faPen} />
                                                <FontAwesomeIcon className="ms-2 text-danger cursor-pointer" icon={faTrashCan} />
                                            </td> 
                                        </tr>))
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">...</a></li>
                                        <li class="page-item"><a class="page-link" href="#">10</a></li>
                                        <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default StockCount;