import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import Inventory from './views/Inventory';
import InventoryAdd from './views/Inventory/inventoryAdd';
import CashManagement from './views/Pos/CashManagement/cashManagement';
import Registers from './views/Pos/Registers/registers';
import Settings from './views/Settings/settings';
import Reports from './views/Reports/reports';
import Customers from './views/OrderManagement/Customers/customers';
import Invoices from './views/OrderManagement/Invoices/invoices';
import Orders from './views/OrderManagement/Orders/orders';
import Payments from './views/StockControl/Payments/payments';
import PurchaseOrder from './views/StockControl/PurchaseOrder/purchaseOrder';
import RemoveStock from './views/StockControl/RemoveStock/removeStock';
import ReturnInventory from './views/StockControl/ReturnInventory/returnInventory';
import StockCount from './views/StockControl/StockCount/stockCount';
import Suppliers from './views/StockControl/Suppliers/suppliers';
import TransferStock from './views/StockControl/TransferStock/transferStock';
import Company from './views/Company/company';
import ComapanyAdd from './views/Company/comapnyAdd';
import BarCode from './views/BarCode/barCode';
import Login from './views/Auth/login';
import Warehouse from './views/Warehouse';
import PaymentMethod from './views/PaymentMethod';
import User from './views/User';
import Role from './views/RoleAndPermission/roles';
import Permission from './views/RoleAndPermission/permissions';
import WarehouseAdd from './views/Warehouse/wareHouseAdd';
import PaymentMethodAdd from './views/PaymentMethod/paymentMethodAdd';

function App(){
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="dashboard" element={<Home />} />
        <Route path="company" element={<Company />} />
        <Route path="company/add" element={<ComapanyAdd />} />
        <Route path="inventory" element={<Inventory />} />
        <Route path="inventory/add" element={<InventoryAdd />} />
        <Route path="barcode" element={<BarCode />} />
        <Route path="pos/registers" element={<Registers />} />
        <Route path="pos/cashmanagement" element={<CashManagement />} />
        <Route path="reports" element={<Reports />} />
        <Route path="ordermanagement/customers" element={<Customers />} />
        <Route path="ordermanagement/invoices" element={<Invoices />} />
        <Route path="ordermanagement/orders" element={<Orders />} />
        <Route path="stockcontrol/payments" element={<Payments />} />
        <Route path="stockcontrol/purchaseorder" element={<PurchaseOrder />} />
        <Route path="stockcontrol/removestock" element={<RemoveStock />} />
        <Route path="stockcontrol/returninventory" element={<ReturnInventory />} />
        <Route path="stockcontrol/stockcount" element={<StockCount />} />
        <Route path="stockcontrol/suppliers" element={<Suppliers />} />
        <Route path="stockcontrol/transferstock" element={<TransferStock />} />
        <Route path="roles-and-permissions/roles" element={<Role />} />
        <Route path="roles-and-permissions/roles/permissions" element={<Permission />} />
        <Route path="users" element={<User />} />
        <Route path="warehouses" element={<Warehouse />} />
        <Route path="warehouses/add" element={<WarehouseAdd />} />
        <Route path="payment-methods" element={<PaymentMethod />} />
        <Route path="payment-methods/add" element={<PaymentMethodAdd />} />
        <Route path="settings" element={<Settings />} />


      </Routes>
    </BrowserRouter>
  );
}
export default App;
