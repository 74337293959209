import React from "react";
import Layout from "../../Layout/default";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";


function ReturnInventory() {
    return(
        <Layout>
            <div className="container">
                <div className="row g-2 mt-2">
                    <div className="col-12">
                        <div className="bg-white border rounded p-3">
                            <button className="btn btn-primary float-end">+ New Return Inventory</button>
                            <div className="fw-bold mt-2">Return Inventory</div>
                            <div class="table-container mt-2">
                                <table class="table mt-2">
                                    <thead>
                                        <tr>
                                            <th className="sticky-column">Invoice Number</th>
                                            <th>Issue Date</th>
                                            <th>Supplier</th>
                                            <th>Location</th>
                                            <th>Invoice Type</th>
                                            <th>Status</th>
                                            <th>Payment Status</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        [1,1,1,1,1,1,1,1,1,1,1,1].map((item, index) => (
                                        <tr>
                                            <td className="sticky-column">204</td>
                                            <td>04/08/2023</td>
                                            <td>New</td>
                                            <td>Active</td>
                                            <td>Digi</td>
                                            <td>Pending</td>
                                            <td>True</td>
                                            <td className="text-center">
                                                <FontAwesomeIcon className="ms-2 text-info cursor-pointer" icon={faPen} />
                                                <FontAwesomeIcon className="ms-2 text-danger cursor-pointer" icon={faTrashCan} />
                                            </td> 
                                        </tr>))
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">...</a></li>
                                    <li class="page-item"><a class="page-link" href="#">10</a></li>
                                    <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                </ul>
                            </nav>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ReturnInventory;