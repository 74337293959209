import './../../App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faBars, faBell, faBuilding, faCaretDown, faCartShopping, faChartLine, faFlag, faGear, faGlobe, faKey, faMoneyBill, faSignOutAlt, faSquareCheck, faTableCellsLarge, faUser, faWarehouse} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from 'react-router-dom';

function Layout({ children, showSidebar }) {

  const [openDropdown, setOpenDropdown] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768 ? true : false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;
    if (width >= 768) {
      setIsSidebarOpen(true);
    }

    if(showSidebar == false){
      setIsSidebarOpen(false);
    }
  }, [])

  const toggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  };

  return (
    <div className='app'>
      <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
        <div className="nav">
          <div className='d-flex w-100 justify-content-between p-3 '>
            <img src='https://platform.rewaatech.com/assets/icons/logo.svg' />
            <div className="menu-toggle">
              <FontAwesomeIcon icon={faBars} onClick={toggleSidebar} />
            </div>
          </div>
          
          <Link to="/company" className="nav-item mb-0 text-decoration-none active">
            <span className="nav-icon"><FontAwesomeIcon icon={faBuilding} /></span>
            <span className="nav-text">Company</span>
          </Link>
          <Link to="/dashboard" className="nav-item mb-0 text-decoration-none active">
            <span className="nav-icon"><FontAwesomeIcon icon={faChartLine} /></span>
            <span className="nav-text">Dashboard</span>
          </Link>
          <Link to="/inventory" className="nav-item text-decoration-none">
            <span className="nav-icon"><FontAwesomeIcon icon={faWarehouse} /></span>
            <span className="nav-text">Inventory</span>
          </Link>
          <Link to="/barcode" className="nav-item text-decoration-none">
            <span className="nav-icon"><FontAwesomeIcon icon={faBarcode} /></span>
            <span className="nav-text">Barcode</span>
          </Link>
          <div className="nav-item" onClick={() => toggleDropdown(1)}>
            <span className="nav-icon"><FontAwesomeIcon icon={faKey} /></span>
            <span className="nav-text">POS</span>
            <span className={`menu-arrow ${openDropdown == 1 ? "open" : ""}`}><FontAwesomeIcon icon={faCaretDown} /></span>
          </div>
          {openDropdown == 1 && (
            <div className="submenu">
              <Link to="/pos/registers" className="nav-text submenu-item">Create Sale</Link>
              {/* <Link to="/pos/cashmanagement" className="nav-text submenu-item">Cash Management</Link> */}
              <Link to="/settings?type=pos" className="nav-text submenu-item">Settings</Link>
            </div>
          )}
          <Link to="/reports" className="nav-item text-decoration-none">
            <span className="nav-icon"><FontAwesomeIcon icon={faFlag} /></span>
            <span className="nav-text">Reports</span>
          </Link>
          <div className="nav-item" onClick={() => toggleDropdown(2)}>
            <span className="nav-icon"><FontAwesomeIcon icon={faSquareCheck} /></span>
            <span className="nav-text">Order Management</span>
            <span className={`menu-arrow ${openDropdown == 2 ? "open" : ""}`}><FontAwesomeIcon icon={faCaretDown} /></span>
          </div>
          {openDropdown == 2 && (
            <div className="submenu">
              <Link to="/ordermanagement/orders" className="nav-text submenu-item">Orders</Link>
              <Link to="/ordermanagement/invoices" className="nav-text submenu-item">Invoices</Link>
              <Link to="/ordermanagement/customers" className="nav-text submenu-item">Customers</Link>
              {/* <Link to="/pos/settings" className="nav-text submenu-item">Settings</Link> */}
            </div>
          )}
          <div className="nav-item" onClick={() => toggleDropdown(3)}>
            <span className="nav-icon"><FontAwesomeIcon icon={faCartShopping} /></span>
            <span className="nav-text">Stock Control</span>
            <span className={`menu-arrow ${openDropdown == 3 ? "open" : ""}`}><FontAwesomeIcon icon={faCaretDown} /></span>
          </div>
          {openDropdown == 3 && (
            <div className="submenu">
              <Link to="/stockcontrol/purchaseorder" className="nav-text submenu-item">Purchase Order</Link>
              <Link to="/stockcontrol/returninventory" className="nav-text submenu-item">Return Inventory</Link>
              <Link to="/stockcontrol/removestock" className="nav-text submenu-item">Remove Stock</Link>
              <Link to="/stockcontrol/transferstock" className="nav-text submenu-item">Transfer Stock</Link>
              <Link to="/stockcontrol/stockcount" className="nav-text submenu-item">Stock Count</Link>
              <Link to="/stockcontrol/payments" className="nav-text submenu-item">Payments</Link>
              <Link to="/stockcontrol/suppliers" className="nav-text submenu-item">Suppliers</Link>
            </div>
          )}
          <Link to="/warehouses" className="nav-item text-decoration-none">
            <span className="nav-icon"><FontAwesomeIcon icon={faWarehouse} /></span>
            <span className="nav-text">Warehouses</span>
          </Link>
          <Link to="/users" className="nav-item text-decoration-none">
            <span className="nav-icon"><FontAwesomeIcon icon={faUser} /></span>
            <span className="nav-text">Users</span>
          </Link>
          <Link to="/payment-methods" className="nav-item text-decoration-none">
            <span className="nav-icon"><FontAwesomeIcon icon={faMoneyBill} /></span>
            <span className="nav-text">Payment Methods</span>
          </Link>
          <Link to="/roles-and-permissions/roles" className="nav-item text-decoration-none">
            <span className="nav-icon"><FontAwesomeIcon icon={faKey} /></span>
            <span className="nav-text">Roles & Permissions</span>
          </Link>
          <div className="nav-item" onClick={() => toggleDropdown(4)}>
            <span className="nav-icon"><FontAwesomeIcon icon={faGear} /></span>
            <span className="nav-text">Settings</span>
            <span className={`menu-arrow ${openDropdown == 4 ? "open" : ""}`}><FontAwesomeIcon icon={faCaretDown} /></span>
          </div>
          {openDropdown == 4 && (
            <div className="submenu">
              <Link to="/settings?type=general" className="nav-text submenu-item">General</Link>
              <Link to="/settings?type=app" className="nav-text submenu-item">App</Link>
            </div>
          )}
        </div>
      </div>

      <div className={`content ${isSidebarOpen ? '' : 'ms-0'}`}>
        <div className="header p-0">
          <div className='d-flex align-items-center p-3'>

            {
              !isSidebarOpen && (
                <div className="menu-toggle me-2 d-none d-md-inline-block">
                  <FontAwesomeIcon icon={faBars} onClick={toggleSidebar} />
                </div>  
              )
            }
            
            <div className='fw-bold fs-5'>Dashboard</div>
          </div>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon icon={faBell} className='me-2' />
            <div className="dropdown">
              <button onClick={toggleProfileDropdown}
                className="btn dropdown-toggle px-3 rounded-0 profile-dropdown"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >
                Super Admin
                <br />
                Role
              </button> 
              <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                {/* <button className="dropdown-item d-flex align-items-center">
                  <FontAwesomeIcon icon={faGlobe} className="me-2" />
                  عربي
                </button> */}
                <button className="dropdown-item d-flex align-items-center">
                  <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                  Logout
                </button>
            </div>
          </div>
        </div>
      </div>

      <div style={{
        marginBottom: '80px',
      }}>
        {children}
      </div>
      
      <div className='app d-md-none'>
        <div className={`footer ${isSidebarOpen ? '' : 'w-100'}`}>
          <div className='d-flex justify-content-center p-3'>
            <div className="brand-logo">Rewaa Platform</div>
            <div className="menu-toggle">
              <FontAwesomeIcon icon={faBars} onClick={toggleSidebar} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Layout;
