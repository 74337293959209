import {React, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Layout/default";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faPen, faRightToBracket, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import CustomModal from "../../components/Modal/Confirmation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Permission() {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const backUp = () => {
        toast.success("Backup Successfully");
    };

    const edit = () => {
        toast.info("You can edit now");
        setTimeout(() => {
            navigate('/company/add');
        }, 1000);
    };

    const login = () => {
        toast.success("Login Successfully");
        setTimeout(() => {
            navigate('/');
        }, 1000);
    }

    const deleteRow = () => {
        toast.error("Deleted successfully");
    }
    
    return(
        <Layout>
            <div className="container">
                <div className="row g-2 mt-2">
                    <div className="bg-white border rounded p-3">
                        <div class="mt-2">
                            <div className="row">
                                <div class="col-12 col-md-6">
                                    <div className="fw-bold">Role</div>
                                    <input className="w-100 border rounded p-2 mt-2" type="text" value="Superadmin" />
                                </div>
                            </div>
                            <div className="row g-2 mt-1">
                                <div className="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label fw-bold" for="flexCheckDefault">
                                            Pos
                                        </label>
                                    </div>
                                    <div class="form-check ms-4">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Create Sale
                                        </label>
                                    </div>
                                    <div class="form-check ms-4">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Delete Sale
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label fw-bold" for="flexCheckDefault">
                                            Invetory
                                        </label>
                                    </div>
                                    <div class="form-check ms-4">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Create
                                        </label>
                                    </div>
                                    <div class="form-check ms-4">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Edit
                                        </label>
                                    </div>
                                    <div class="form-check ms-4">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Delete
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <Link to="#" className="btn btn-primary">Save</Link>
                            </div>

                        </div>
                    </div>
                </div>
                <CustomModal
                    showModal={showModal}
                    closeModal={closeModal}
                />
            </div>
            <ToastContainer />
        </Layout>
        
    )
}

export default Permission;