import React from "react";
import { Link } from "react-router-dom";

function Login() {
    return(
        <div className="container">
            <div className='p-3'>
                
            </div>
            <div className="row justify-content-center g-2 mt-2">
                <div className="col-12 col-md-6">
                    <div className="bg-white border rounded p-3">
                        <div className="row g-3">
                            
                            <div class="col-12 mb-5">
                                <h3>Welcome to</h3>
                                <img src='https://platform.rewaatech.com/assets/icons/logo.svg' height={70} />
                            </div>

                            <div className="col-12">
                                <div className="fw-bold">Email</div>
                                <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Enter your email" />
                            </div>

                            <div className="col-12">
                                <div className="fw-bold">Password</div>
                                <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Password" />
                            </div>
                        </div>
                        <Link to="/dashboard" className="btn btn-primary w-100 mt-4">Login</Link>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Login;