import {React, useState, useCallback} from "react";
import Layout from "../Layout/default";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import UnitModal from "../../components/Modal/UnitModal";
import CategoryModal from "../../components/Modal/CategoryModal";
import {useDropzone} from 'react-dropzone'
import Select from 'react-select';

function InventoryAdd() {

    const [showModal, setShowModal] = useState('');
    const [type, setType] = useState('');

    const openModal = (val) => {
        setShowModal(val);
    };

    const closeModal = () => {
        setShowModal('');
    };

    const onDrop = useCallback(acceptedFiles => {
      }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const options = [
        { value: 'option 1', label: 'Option 1' },
        { value: 'option 2', label: 'Option 2' }
    ]
      
    const discountOptions = [
        { value: 'percentage', label: 'Percentage Discount' },
        { value: 'fixed', label: 'Fixed Discount' }
    ]

    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          borderColor: state.isFocused ? 'black' : provided.borderColor, 
          boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow, 
          '&:hover': {
            borderColor: state.isFocused ? 'black' : provided.borderColor,
          },
        }),
    };
      
    return(
        <Layout>
            <div className="container">
                <div className="row g-2 mt-2">
                    <div className="col-12">
                        <div className="bg-white border rounded p-3">
                            <div className="">
                                <div className="fw-bold ">Product Type 
                                    {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                </div>
                                <p className="mt-2">You can't edit this choice after saving the product</p>
                            </div>
                            <div class="radio-buttons row">
                                <label class="custom-radio col-6 col-md-3">
                                    <input type="radio" name="radio" onClick={() => setType(1) }/>
                                    <span class="radio-btn w-100"><i class="las la-check"></i>
                                        <div class="hobbies-icon d-flex align-items-center">
                                            <h3 class="text-nowrap p-2">Simple Product</h3>
                                            {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                        </div>
                                    </span>
                                </label>
                                <label class="custom-radio col-6 col-md-3">
                                    <input type="radio" name="radio" onClick={() => setType(2) } />
                                    <span class="radio-btn w-100"><i class="las la-check"></i>
                                        <div class="hobbies-icon d-flex align-items-center">
                                            <h3 class="text-nowrap p-2">Variable Product</h3>
                                            {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                        </div>
                                    </span>
                                </label>
                                {/* <label class="custom-radio">
                                    <input type="radio" name="radio" />
                                    <span class="radio-btn"><i class="las la-check"></i>
                                        <div class="hobbies-icon d-flex align-items-center">
                                            <h3 class="text-nowrap p-2">Composite Product</h3>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                        </div>
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input type="radio" name="radio" />
                                    <span class="radio-btn"><i class="las la-check"></i>
                                        <div class="hobbies-icon d-flex align-items-center">
                                            <h3 class="text-nowrap p-2">Digital Product</h3>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                        </div>
                                    </span>
                                </label>  */}
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="bg-white border rounded p-3">
                            <div className="">
                                <div className="fw-bold ">Product Details </div>
                                <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Simple Product Name" />                                <div class="input-group mt-2">
                                <div className="d-flex w-100">
                                    <input className="border rounded p-2 w-100" type="area" placeholder="Barcode" />
                                    <span class="input-group-text btn btn-primary inverse fw-600 cursor-pointer" id="basic-addon2">Generate</span>
                                </div>
                            </div>

                            <div className="row g-2 mt-1">
                                <div className="col-12">
                                    {/* <div class="input-group w-100">
                                        <Select options={options} className="w-100-45" placeholder="ss"/>
                                        <button onClick={() => openModal('category')} class="input-group-text btn btn-primary inverse fw-600 cursor-pointer" id="basic-addon2">+</button>
                                    </div> */}
                                
                                    {/* <div class="input-group mt-2">
                                        <select className="border form-control shadow-none rounded p-2" type="area" placeholder="Category">
                                            <option>Category</option>
                                            <option>Shoes</option>
                                        </select>
                                    </div> */}
                                </div>
                            </div>

                            <div className="col-12">
                                <textarea className="w-100 border rounded p-2" type="area" placeholder="Product Description" rows={3}></textarea>
                            </div>


                            <div className="row g-2">
                                
                                <div className="fw-bold mt-3">Unit</div>
                                <div className="col-12 col-md-4">
                                    <div class="input-group">
                                        <div class="input-group w-100">
                                            <Select options={options} styles={customStyles} className="w-100-45" placeholder="Purchase Unit"/>
                                            <button onClick={() => openModal('category')} class="input-group-text btn btn-primary inverse fw-600 cursor-pointer" id="basic-addon2">+</button>
                                        </div>
                                        {/* <select className="border form-control shadow-none rounded p-2">
                                            <option>Purchase Unit</option>
                                            <option>Kg</option>
                                        </select>
                                        <button onClick={() => openModal('unit')} class="input-group-text btn btn-primary inverse fw-600 cursor-pointer" id="basic-addon2">+</button> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div class="input-group w-100">
                                            <Select options={options} styles={customStyles} className="w-100-45" placeholder="Sale Unit"/>
                                            <button onClick={() => openModal('category')} class="input-group-text btn btn-primary inverse fw-600 cursor-pointer" id="basic-addon2">+</button>
                                    </div>
                                </div>

                                <div className="fw-bold mt-3">Pricing</div>
                                <div className="col-12 col-md-4">
                                    <div class="input-group">
                                        <input className="w-100 border rounded p-2 " type="area" placeholder="Purchase Price" />
                                        {/* <input className="border form-control shadow-none rounded p-2" type="area" placeholder="Purchase Price" /> */}
                                        {/* <button class="input-group-text bg-primary text-white cursor-pointer" id="basic-addon2">+</button> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div class="input-group">
                                        <input className="w-100 border rounded p-2" type="area" placeholder="Sale Price" />
                                    {/* <button class="input-group-text bg-primary text-white cursor-pointer" id="basic-addon2">+</button> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div class="input-group">
                                        <input className="w-100 border rounded p-2 " type="area" placeholder="Wholesale Price" />
                                    </div>
                                </div>

                                <div className="col-12 col-md-4">
                                    <div class="input-group">
                                        <div class="input-group w-100">
                                            <Select options={discountOptions} styles={customStyles} className="w-100"/>
                                            {/* <button onClick={() => openModal('category')} class="input-group-text btn btn-primary inverse fw-600 cursor-pointer" id="basic-addon2">+</button> */}
                                        </div>
                                        {/* <button class="input-group-text bg-primary text-white cursor-pointer" id="basic-addon2">+</button> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div class="input-group">
                                        <input className="w-100 border rounded p-2 " type="area" placeholder="Discount Value" />
                                    {/* <button class="input-group-text bg-primary text-white cursor-pointer" id="basic-addon2">+</button> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    
                                </div>
                                <div className="fw-bold mt-3">Stock </div>
                                <div className="col-12 col-md-4">
                                    <div class="input-group">
                                        <input className="w-100 border rounded p-2 " type="area" placeholder="Initial Stock" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div class="input-group">
                                        <input className="w-100 border rounded p-2 " type="area" placeholder="Stock" />
                                    {/* <button class="input-group-text bg-primary text-white cursor-pointer" id="basic-addon2">+</button> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div class="input-group">
                                        <input className="w-100 border rounded p-2 " type="area" placeholder="Mini Stock Alert" />
                                    {/* <button class="input-group-text bg-primary text-white cursor-pointer" id="basic-addon2">+</button> */}
                                    </div>
                                </div>
                                
                                {
                                    type == 1 && (<>
                                        <div className="fw-bold mt-3">Variation </div>
                                        <div class="table-responsive"> 
                                            <table class="table table-bordered">
                                                <thead >
                                                    <tr>
                                                        <th>Inventory</th>
                                                        <th>Stock</th>
                                                        <th>Quantity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><input className="w-100 border rounded p-2 " type="area" placeholder="Inventory" /></td>
                                                        <td><input className="w-100 border rounded p-2 " type="area" placeholder="Stock" /></td>
                                                        <td><input className="w-100 border rounded p-2 " type="area" placeholder="Qty" /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </>)
                                }

                                {
                                    type == 2 && (<>
                                        <div className="fw-bold mt-3">Variation </div>
                                        <div class="table-responsive"> 
                                            <table class="table table-bordered table-hover">
                                                <thead class="">
                                                    <tr>
                                                        <th>Inventory</th>
                                                        <th>Stock</th>
                                                        <th>Quantity</th>
                                                        <th>Stock</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><input className="border form-control shadow-none rounded p-2" type="area" placeholder="Inventory" /></td>
                                                        <td><input className="border form-control shadow-none rounded p-2" type="area" placeholder="Stock" /></td>
                                                        <td><input className="border form-control shadow-none rounded p-2" type="area" placeholder="Qty" /></td>
                                                        <td><input className="border form-control shadow-none rounded p-2" type="area" placeholder="Mini Stock Alert" /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </>)
                                }

                            </div>
                        </div>
                        {/* <div class="form-check mt-2">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault">
                                Sellable 
                                <FontAwesomeIcon icon={faInfoCircle} />
                                Uncheck if don't want to sell this product
                            </label>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="bg-white border rounded p-3 mt-2">
                <div className="fw-bold">Add all Images </div>
                <div className="border-dashed border-e1 text-center mt-2">
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                            <p>Drop the files here ...</p> :
                            <div className="py-3">
                                <div className="mt-2 d-flex align-items-center justify-content-center">
                                    <img className="me-2" src="C:\Users\Super\Desktop\Reactjs\react-project\public\dummy-image-icon.svg" />
                                    <b>Drop all Images here</b>
                                    <span className="mx-2">or</span>
                                    <button className="btn btn-primary inverse fw-600"><FontAwesomeIcon className="me-2" icon={faArrowUpFromBracket} />Browse Images</button>
                                </div>
                                <div className="mt-3">Image size shouldn't exceed <b>2 MB</b></div>
                                <div className="mb-4"><b>JPEG, PNG, PDF</b> are supported</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="bg-white border rounded p-3 mt-2">
                <div className="fw-bold">Classification</div>
                <div className="row g-2 mt-2">
                    <div className="col-12 col-md-12">
                        <div class="input-group w-100">
                            <Select options={options} styles={customStyles} className="w-100-45" placeholder="Category"/>
                            <button onClick={() => openModal('category')} class="input-group-text btn btn-primary inverse fw-600 cursor-pointer" id="basic-addon2">+</button>
                        </div>
                    </div>
                    <div className="col-12">
                        <div class="input-group w-100">
                            <Select options={options} styles={customStyles} className="w-100-45" placeholder="Supplier" />
                            <button onClick={() => openModal('category')} class="input-group-text btn btn-primary inverse fw-600 cursor-pointer" id="basic-addon2">+</button>
                        </div>
                    </div>
                    <div className="col-12">
                        <div class="input-group w-100">
                            <Select options={options} styles={customStyles} className="w-100-45" placeholder="Brand"/>
                            <button onClick={() => openModal('category')} class="input-group-text btn btn-primary inverse fw-600 cursor-pointer" id="basic-addon2">+</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="bg-white border rounded p-3 mt-2">
                <div className="fw-bold">Shipping Details</div>
                <div className="row g-2 mt-2">
                    <div className="col-12 col-md-12">
                        <input className="border form-control shadow-none rounded" type="area" placeholder="Weight (kg)" />
                    </div>
                    <div className="col-12 col-md-4">
                        <input className="border form-control shadow-none rounded" type="area" placeholder="Length (cm)" />
                    </div>

                    <div className="col-12 col-md-4">
                        <input className="border form-control shadow-none rounded" type="area" placeholder="Width (cm)" />
                    </div>

                    <div className="col-12 col-md-4">
                        <input className="border form-control shadow-none rounded" type="area" placeholder="Height (cm)" />
                    </div>
                </div>
            </div> */}
            <UnitModal
                showModal={showModal == 'unit' ? true : false}
                closeModal={closeModal}
            />
            <CategoryModal
                showModal={showModal == 'category' ? true : false}
                closeModal={closeModal}
            />
            
        </div>
        </Layout>
    )
}

export default InventoryAdd;