import React from 'react';
import Layout from '../Layout/default';
import { useSearchParams } from 'react-router-dom';

function Settings() {
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type'); 

    return(
        <Layout>
            <div className="container">
                <div className="row g-2 mt-2">
                    <div className="col-12">
                        <div className="bg-white border rounded p-3">
                            {
                                type == 'general' && (
                                    <>
                                        <div className="row g-3">
                                            <div class="col-12">
                                                <div className="fw-bold">General Settings</div>
                                            </div>

                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Logo</div>
                                                <input className="w-100 border rounded p-2 mt-2" type="file" />
                                            </div>
                                            <div class="col-12 col-md-6">
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Company Name</div>
                                                <input className="w-100 border rounded p-2 mt-2" type="text" value="" />
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Website</div>
                                                <input className="w-100 border rounded p-2 mt-2" type="text" value="" />
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Email</div>
                                                <input className="w-100 border rounded p-2 mt-2" type="text" value="" />
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Phone</div>
                                                <input className="w-100 border rounded p-2 mt-2" type="text" value="" />
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Address</div>
                                                <input className="w-100 border rounded p-2 mt-2" type="text" value="" />
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Vat</div>
                                                <input className="w-100 border rounded p-2 mt-2" type="text" value="" />
                                            </div>
                                            {/* <div class="col-12 col-md-6">
                                                <div className="fw-bold">Default Warehouse</div>
                                                <select className="w-100 border rounded p-2 mt-2" type="text" value="">
                                                    <option>Select warehouse</option>
                                                </select>
                                            </div> */}

                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Primary Color</div>
                                                <input className="w-100 border rounded p-2 mt-2" type="color" value="#FF00FF" />
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Secondary Color</div>
                                                <input className="w-100 border rounded p-2 mt-2" type="color" />
                                            </div>
                                            
                                        </div>
                                    </>
                                )
                            }
                            {
                                type == 'app' && (
                                    <>
                                        <div className="row g-3">

                                            <div class="col-12">
                                                <div className="fw-bold">App Settings</div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Recent Sale Limit</div>
                                                <input className="w-100 border rounded p-2 mt-2" type="text" placeholder='0 for no limit' />
                                            </div>

                                        </div>
                                    </>
                                )
                            }
                            {
                                type == 'application' && (
                                    <>
                                        <div className="row g-3">

                                            <div class="col-12">
                                                <div className="fw-bold">App Settings</div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Recent Sale Limmit</div>
                                                <input className="w-100 border rounded p-2 mt-2" type="text" placeholder='0 for no limit' />
                                            </div>

                                            <div class="col-12">
                                                <div className="fw-bold">Pos Settings</div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Sale Sound</div>
                                                <select className="w-100 border rounded p-2 mt-2">
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div className="fw-bold">Print</div>
                                                <select className="w-100 border rounded p-2 mt-2">
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </select>
                                            </div>

                                        </div>
                                    </>
                                )
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Settings;