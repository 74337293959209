import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Layout from "../Layout/default";


function ComapanyAdd() {
    return(
        <Layout>
            <div className="container">
                <div className="row g-2 mt-2">
                    <div className="col-12">
                        <div className="bg-white border rounded p-3">
                            <div className="row g-3">
                                <div class="col-12">
                                    <div className="fw-bold">Company Information</div>
                                </div>

                                <div class="col-12 col-md-6">
                                    <div className="fw-bold">Company Name </div>
                                    <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Company Name" />
                                </div>

                                <div class="col-12 col-md-6">
                                    <div className="fw-bold">Print Name </div>
                                    <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Print Name" />
                                </div>

                                <div class="col-12 col-md-6">
                                    <div className="fw-bold">Short Name </div>
                                    <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Short Name" />
                                </div>

                                <div class="col-12 col-md-6">
                                    <div className="fw-bold">Country </div>
                                    <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Country" />
                                </div>

                                <div class="col-12 col-md-6">
                                    <div className="fw-bold">F.Y Beginning from </div>
                                    <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="F.Y Beginning from " />
                                </div>
                                
                                <div class="col-12 col-md-6">
                                    <div className="fw-bold">Address </div>
                                    <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Address" />
                                </div>

                                <div class="col-12 col-md-6">
                                    <div className="fw-bold">Pincode </div>
                                    <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Pincode" />
                                </div>

                                <div class="col-12 col-md-6">
                                    <div className="fw-bold">Tel-No.</div>
                                    <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Tel-No." />
                                </div>

                                <div class="col-12 col-md-6">
                                    <div className="fw-bold">E-Mail</div>
                                    <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="E-Mail" />
                                </div>

                            </div>
                            <div className="row g-2 mt-2">
                                <div className="col-12 col-md-6"> 
                                    <div className="bg-white border rounded p-3">
                                        <div className="fw-bold">Account Details</div>
                                        <div className="fw-bold mt-2">Name</div>
                                        <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Name" />
                                        <div className="fw-bold mt-2">E-Mail</div>
                                        <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="E-Mail" />
                                        <div className="fw-bold mt-2">Mobile</div>
                                        <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Mobile" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="bg-white border rounded p-3">
                                        <div className="fw-bold">CA Details</div>
                                        <div className="fw-bold mt-2">Name</div>
                                        <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Name" />
                                        <div className="fw-bold mt-2">E-Mail</div>
                                        <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="E-Mail" />
                                        <div className="fw-bold mt-2">Mobile</div>
                                        <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Mobile" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="bg-white border rounded p-3">
                            <div className="">
                                <div className="fw-bold">Additional Information</div>
                                <div className="row g-2 mt-2">
                                    <div className="col-12 col-md-6"> 
                                        <div className="bg-white border rounded p-3">
                                            <div className="fw-bold">Currency Information</div>
                                            <div class="dropdown">
                                                <div className="fw-bold mt-2">Currency Position</div>
                                                <select class="form-select mt-2 shadow-none" id="selectDropdown">
                                                    <option value="1">Left</option>
                                                    <option value="2">Right</option>
                                                </select>
                                            </div>
                                            <div className="fw-bold mt-2">Currrency Symbol</div>
                                            <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Currrency Symbol" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="bg-white border rounded p-3">
                                            <div className="fw-bold">GST/VAT Information</div>
                                            <div class="dropdown">
                                                <div className="fw-bold mt-2">Enable GST/VAT </div>
                                                <select class="form-select mt-2 shadow-none" id="selectDropdown">
                                                    <option value="1">Yes</option>
                                                    <option value="2">No</option>
                                                </select>
                                            </div>
                                            <div className="fw-bold mt-2">Enable Tax-Rate 1</div>
                                            <input className="w-100 border rounded p-2 mt-2" type="area" placeholder="Tax-Rate" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary mt-2">Save</button>
            </div>
        </Layout>
    )
}

export default ComapanyAdd;