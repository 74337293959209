import {React, useState} from 'react';
import Layout from '../../Layout/default';
import { Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faMagnifyingGlass, faPen, faPerson, faRightToBracket, faRotateRight, faSearch, faTrashCan } from "@fortawesome/free-solid-svg-icons";

function Registers() {

    return(
        <Layout showSidebar={false}>
            <div className="container-fluid">
                <div className="row g-2 mt-2">
                    <div className="bg-white p-3">
                        <div className='row'>
                            <div className='col-12 col-lg-8'>
                                <div className="row g-3">
                                    <div className='col-12 col-lg-8'>
                                    <div class="input-group">
                                        <span class="input-group-text bg-white cursor-pointer border-end-0"><FontAwesomeIcon className="cursor-pointer" icon={faSearch} /></span>
                                        <input className="border border-start-0 p-2 form-control shadow-none" type="area" placeholder="Search" />
                                    </div>
                                    </div>
                                    <div className='col-12 col-lg-4'>
                                        <div className="d-flex align-items-center">
                                            <span className="w-100 p-2">Sell at</span>
                                            <div class="radio-inputs">
                                                <label>
                                                    <input class="radio-input" type="radio" name="engine" />
                                                    <span class="radio-tile shadow-none btn">
                                                        <span class="radio-label">Retail</span>
                                                    </span>
                                                </label>
                                                <label>
                                                    <input class="radio-input" type="radio" name="engine" />
                                                    <span class="radio-tile shadow-none btn">
                                                        <span class="radio-label">Wholesale</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="table-responsive mt-2">
                                        <table class="table table-bordered table-hover">
                                            <thead class="">
                                                <tr>
                                                    <th></th>
                                                    <th>Product Name</th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th>Total Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='text-center'><FontAwesomeIcon className="cursor-pointer" icon={faTrashCan} /></td>
                                                    <td>Sample Product</td>
                                                    <td width={100}>
                                                        <input className="form-control shadow-none" type="area" value={200} />
                                                    </td>
                                                    <td width={100}>
                                                        <input className="form-control shadow-none" type="area" value={1} />
                                                    </td>
                                                    <td className='fw-bold'>200</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-12'>
                                        <div className='text-center p-5'>
                                            <img src="https://platform.rewaatech.com/assets/images/cart-empty.svg" />
                                            <p className="my-2 fs-12">You didn’t add any products to this cart yet, <b>scan</b> or <b>type products name or SKU</b> to add them.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='bg-white border rounded d-flex align-items-center justify-content-between'>
                                            <div className='d-flex align-items-center bg-white cursor-pointer w-100 p-2'>
                                                <FontAwesomeIcon className='m-2' icon={faPerson} />
                                                <span>Customer</span>
                                            </div>
                                            {/* <div className='d-flex align-items-center bg-white'>
                                                <FontAwesomeIcon className='m-2' icon={faRotateRight} />
                                                <span>Continue Sale</span>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <div className='bg-white border rounded h-100 bg-white px-3 py-2'>
                                            <div className="d-flex justify-content-between mb-2">
                                                <div className="">Subtotal (Tax Exclusive)</div>
                                                <div className="d-flex fw-bold">0</div>
                                            </div>
                                            <div className="d-flex justify-content-between mb-2">
                                                <div className="">Discount Amount</div>
                                                <div className="d-flex fw-bold">0</div>
                                            </div>
                                            <div className="d-flex justify-content-between mb-2">
                                                <div className="">Promotions</div>
                                                <div className="d-flex fw-bold">0</div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="">Tax</div>
                                                <div className="d-flex fw-bold">0</div>
                                            </div>

                                            <hr />

                                            <div className="d-flex justify-content-between">
                                                <div className='fw-bold'>Add</div>
                                                <div className='d-flex'>
                                                    <div className='me-3'>Note</div>
                                                    <div>Discount</div>
                                                </div>
                                            </div>

                                            <hr />

                                            <div className="d-flex justify-content-between">
                                                <div className="fw-bold">Total (Tax Inclusive)</div>
                                                <div className="d-flex fw-bold">0 TZS</div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <div className=''>
                                            <div className="btn btn-primary d-flex justify-content-between">
                                                <div className="fw-bold">Pay 0 Items</div>
                                                <div className="d-flex fw-bold">0 TZS</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Registers;