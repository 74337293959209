import React from "react";
import Layout from "../../Layout/default";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";

function Suppliers() {
    return(
        <Layout>
            <div className="container">
                <div className="row g-2 mt-2">
                    <div className="col-12">
                        <div className="bg-white border rounded p-3">
                            <button className="btn btn-primary float-end">+ New Supplier</button>
                            <div className="fw-bold mt-2">Suppliers</div>
                            <div class="table-container mt-2">
                                <table class="table mt-2">
                                    <thead class="">
                                        <tr>
                                            <th className="sticky-column">Supplier Code</th>
                                            <th>Supplier Name</th>
                                            <th>Total Paid</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                            <th>Net Due</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            [1,1,1,1,1,1,1,1,1,1,1,1].map((item, index) => (
                                                <tr>
                                                    <td className="sticky-column">204</td>
                                                    <td>Zahidz</td>
                                                    <td>500</td>
                                                    <td>300</td>
                                                    <td>200</td>
                                                    <td>200</td>
                                                    <td className="text-center">
                                                        <FontAwesomeIcon className="ms-2 text-info cursor-pointer" icon={faPen} />
                                                        <FontAwesomeIcon className="ms-2 text-danger cursor-pointer" icon={faTrashCan} />
                                                    </td> 
                                                </tr>))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">...</a></li>
                                        <li class="page-item"><a class="page-link" href="#">10</a></li>
                                        <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Suppliers;