import {React, useState} from "react";
import Layout from "../Layout/default";
import './../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import DashboardStatusCard from "../../components/card/dashboardStatusCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowUp, faCartShopping, faCircleArrowUp, faInfoCircle, faMoneyBill, faSackDollar, faWallet } from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs, Card } from 'react-bootstrap';

function Home() {

    const [key, setKey] = useState('days');

    const NoData = ({ message, subtitle, image }) => (
        <div className="row justify-content-center text-center p-3">
            <div className="col-12 col-md-6">
                <img src= {image} />
                <h4 className="font-bold my-2">{message}</h4>
                {subtitle}
            </div>
        </div>
    );

    return(
        <Layout>
            <div className="container">
                <div className="quick-header p-0">
                    <div className='d-flex align-items-center'>
                        <div className='fw-bold fs-5'>Quick Preview</div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className="dropdown">
                            <button
                                className="btn dropdown-toggle px-3 rounded-0 profile-dropdown"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                >
                                Branches: All
                            </button>
                            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                <button className="dropdown-item d-flex align-items-center">
                                    Branch 1
                                </button>
                                <button className="dropdown-item d-flex align-items-center">
                                    Branch 2
                                </button>
                            </div>
                        </div>
                        <div className="dropdown">
                            <button 
                                className="btn dropdown-toggle px-3 rounded-0 profile-dropdown"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                >
                                Date
                            </button>
                            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                <button className="dropdown-item d-flex align-items-center">
                                    Date 1
                                </button>
                                <button className="dropdown-item d-flex align-items-center">
                                    Date 2
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-3 mt-0">
                    <div className="col-12 col-md-4">
                        <DashboardStatusCard 
                            title="Sales (Tax Inclusive)" 
                            firstIcon={<FontAwesomeIcon icon={faCircleArrowUp} />}
                            secondIcon={<FontAwesomeIcon icon={faInfoCircle} />}
                            lastMonthTitle="Last Month"
                            lastMonthSubTitle="0.00 SR"
                            thisMonthTitle="Target (This Month)"
                            thisMonthSubTitle="0.0 SR"
                            price="0.0 SR"
                            percentage="0%"
                            icon={<FontAwesomeIcon icon={faArrowUp} />}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <DashboardStatusCard 
                            link="/reports" 
                            linkText="View Reports"
                            linkArrow={<FontAwesomeIcon icon={faArrowRight} />}
                            title="Sales Transactions" 
                            firstIcon={<FontAwesomeIcon icon={faCartShopping} />}
                            secondIcon={<FontAwesomeIcon icon={faInfoCircle} />}
                            lastMonthTitle="Last Month"
                            lastMonthSubTitle="0"
                            price="0"
                            percentage="0%"
                            icon={<FontAwesomeIcon icon={faArrowUp} />}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <DashboardStatusCard 
                            link="/reports" 
                            linkText="Install Expenses App"
                            title="Net Income" 
                            firstIcon={<FontAwesomeIcon icon={faMoneyBill} />}
                            secondIcon={<FontAwesomeIcon icon={faInfoCircle} />}
                            lastMonthTitle="Last Month"
                            lastMonthSubTitle="0.00 SR"
                            price="0.0 SR"
                            percentage="0%"
                            linkArrow={<FontAwesomeIcon icon={faArrowRight} />}
                            icon={<FontAwesomeIcon icon={faArrowUp} />}
                        />
                    </div>
                </div>
                <div className="row g-3 mt-0">
                    <div className="col-12 col-md-8">
                        <Card className="p-3 card-height">
                            <div className="top-products-section">
                                <div className="product-title-part d-flex justify-content-between">
                                    <div>
                                        <span className="icon-green-background">
                                            <FontAwesomeIcon icon={faMoneyBill} />
                                        </span>
                                        <span className="title mx-1">Sales</span>
                                        <span className="tax-inclusive">(Tax Inclusive)</span>
                                    </div>
                                </div>
                                <Tabs
                                    id="sales-tabs"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="pt-2 d-flex"
                                    >
                                    <Tab eventKey="days" title="Days">
                                        <NoData message="No statistics available" subtitle="Please make a sale to view the chart" image="https://platform.rewaatech.com/assets/images/empty-chart.svg" />
                                    </Tab>
                                    <Tab eventKey="products" title="Products">
                                        <NoData message="No statistics available" subtitle="Please make a sale to view the chart" image="https://platform.rewaatech.com/assets/images/empty-chart.svg" />
                                    </Tab>
                                    <Tab eventKey="categories" title="Categories">
                                        <NoData message="No statistics available" subtitle="Please make a sale to view the chart" image="https://platform.rewaatech.com/assets/images/empty-chart.svg" />
                                    </Tab>
                                    <Tab eventKey="branches" title="Branches">
                                        <NoData message="No statistics available" subtitle="Please make a sale to view the chart" image="https://platform.rewaatech.com/assets/images/empty-chart.svg" />
                                    </Tab>
                                    <Tab eventKey="salesmen" title="Salesmen">
                                        <NoData message="No Data Available" subtitle="Please enable salesmen feature in the POS settings and make sure to add salesman to each invoice" image="https://platform.rewaatech.com/assets/images/salesmen-empty-chart.svg" />
                                    </Tab>
                                </Tabs>
                            </div>
                        </Card>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="mb-3">
                            <DashboardStatusCard 
                                title="Today's Inventory Value" 
                                firstIcon={<FontAwesomeIcon icon={faSackDollar} />}
                                secondIcon={<FontAwesomeIcon icon={faInfoCircle} />}         
                                lastMonthSubTitle="0.00 SR"
                                textEnd="Not affected by date filter nor external channel"
                            />
                        </div>
                        <div>
                            <div className='card h-100 bg-white px-3 py-2'>
                                <div className="mb-1 d-flex justify-content-between align-items-center">
                                    <div className='d-flex'>
                                        <div className='me-2'><FontAwesomeIcon icon={faWallet} /></div>
                                        <div>Amount Collected</div>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="">Cash</div>
                                    <div className="d-flex fw-bold">0.00 S.R</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="">Credit Cash</div>
                                    <div className="d-flex fw-bold">0.00 S.R</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="">Debit</div>
                                    <div className="d-flex fw-bold">0.00 S.R</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="">Total(Tax Inclusive)</div>
                                    <div className="d-flex fw-bold">0.00 S.R</div>
                                </div>
                                <div>
                                    <p>Not affected by external channel filter</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Home;