import {React, useState} from "react";
import Layout from "../Layout/default";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';


function BarCode() {

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return(
        <Layout>
            <div className="container">
                <div className="row g-2 mt-2">
                    <div class="col-12 col-md-6">
                        <div class="input-group">
                            <span class="input-group-text bg-white cursor-pointer border-end-0"><FontAwesomeIcon className="cursor-pointer" icon={faSearch} /></span>
                            <input className="border border-start-0 p-2 form-control shadow-none" type="area" placeholder="Search" />
                        </div>
                    </div>
                    <div className="bg-white border rounded p-3">
                        <div class="table-responsive mt-2">
                            <table class="table table-bordered table-hover">
                                <thead class="">
                                    <tr>
                                        <th>Item</th>
                                        <th>Barcode</th>
                                        <th>Qty.</th>
                                        <th className="text-center">Print</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    [1,1,1,1,1,1,1,1].map((item, index) => (
                                        <tr>
                                            <td>Zahidaz</td>
                                            <td width={200}>1000001</td>
                                            <td width={100}>
                                                <input className="form-control shadow-none" type="area" value={1} />
                                            </td>
                                            <td width={100} className="text-center">   
                                                <input 
                                                    className="form-check-input shadow-none" 
                                                    type="checkbox" 
                                                    id="flexCheckDefault" 
                                                    checked={isChecked} 
                                                    onChange={handleCheckboxChange} 
                                                />
                                            </td>
                                        </tr>))
                                }
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">...</a></li>
                                    <li class="page-item"><a class="page-link" href="#">10</a></li>
                                    <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                </ul>
                            </nav>
                        </div>
                        <button className="btn btn-primary">Print</button>
                    </div>
                    {/* <div className="col-12 text-center py-5"> 
                        <div>
                            <img src="https://platform.rewaatech.com/assets/images/empty-product.svg" />
                        </div>
                        <div className="py-4">
                            <div>You haven't added any comapny yet</div>
                        </div>
                        <div>
                            <Link to="/company/add" className="btn btn-primary">+ New Company</Link>
                        </div>
                    </div> */}
                    
                </div>
            </div>
        </Layout>
    )
}

export default BarCode;