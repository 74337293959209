import {React, useState} from "react";
import Layout from "../Layout/default";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCartShopping, faDivide, faStar, faTicket, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import ReportTypeCard from "../../components/card/reportTypeCard";

function Reports() {

    const [type, setType] = useState('');

    return(
        <Layout>
            <div className="container">
                <div className="row g-2 mt-2">
                    {/* <div className="col-12 col-md-2">
                        <div onClick={() => setType(1) } className="border rounded d-flex bg-white p-3 cursor-pointer">
                            <div>
                                <FontAwesomeIcon icon={faStar} />
                            </div>
                            <div className="mx-2">Favorite</div> 
                        </div>
                    </div> */}

                    <div className="col-12 col-md-3">
                        <div onClick={() => setType(2) } className="border rounded d-flex bg-white p-3 cursor-pointer">
                            <div>
                                <FontAwesomeIcon icon={faTicket} />
                            </div>
                            <div className="mx-2">Sales</div> 
                        </div>
                    </div>

                    <div className="col-12 col-md-3">
                        <div onClick={() => setType(3) } className="border rounded d-flex bg-white p-3 cursor-pointer">
                            <div>
                                <FontAwesomeIcon icon={faWarehouse} />
                            </div>
                            <div className="mx-2">Inventory</div> 
                        </div>
                    </div>

                    <div className="col-12 col-md-3">
                        <div onClick={() => setType(4)} className="border rounded d-flex bg-white p-3 cursor-pointer">
                            <div>
                                <FontAwesomeIcon icon={faDivide} />
                            </div>
                            <div className="mx-2">Taxes</div> 
                        </div>
                    </div>

                    <div className="col-12 col-md-3">
                        <div onClick={() => setType(5)} className="border rounded d-flex bg-white p-3 cursor-pointer">
                            <div>
                                <FontAwesomeIcon icon={faCartShopping} />
                            </div>
                            <div className="mx-2">Stock Control</div> 
                        </div>
                    </div>
                </div>
                {
                    type == 2 && (<div className="row g-2 mt-2">
                        <div className="col-12 col-md-6">
                            <ReportTypeCard
                                title="Customer Debit Lifetime"
                                midText="Displays the cumulative debit transactions for each customer over their entire lifetime, providing insights into their payment history and outstanding balances."
                                icon={<FontAwesomeIcon icon={faCalendar} /> }
                                textEnd="Last Opened"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <ReportTypeCard
                                title="Sales By Location"
                                midText="Breaks down sales data by specific locations, allowing you to compare performance, identify top-performing locations, and make informed business decisions."
                                icon={<FontAwesomeIcon icon={faCalendar} /> }
                                textEnd="Last Opened"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <ReportTypeCard
                                title="Sales By Category"
                                midText="Categorizes sales data based on product categories, helping you understand which product categories contribute the most to your overall sales."
                                icon={<FontAwesomeIcon icon={faCalendar} /> }
                                textEnd="Last Opened"
                            />
                        </div>
                    </div>)
                }

{
                    type == 3 && (<div className="row g-2 mt-2">
                        <div className="col-12 col-md-6">
                            <ReportTypeCard
                                title="Obsolete Inventory"
                                midText="Provides information on inventory items that have become obsolete and need to be managed or disposed of accordingly."
                                icon={<FontAwesomeIcon icon={faCalendar} /> }
                                textEnd="Last Opened"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <ReportTypeCard
                                title="Sale By Product"
                                midText="Presents sales data categorized by individual products, enabling you to analyze the performance and popularity of each item."
                                icon={<FontAwesomeIcon icon={faCalendar} /> }
                                textEnd="Last Opened"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <ReportTypeCard
                                title="Stock Value"
                                midText="Calculates the total value of current stock based on the unit costs and quantities of inventory items."
                                icon={<FontAwesomeIcon icon={faCalendar} /> }
                                textEnd="Last Opened"
                            />
                        </div>
                    </div>)
                }

{
                    type == 4 && (<div className="row g-2 mt-2">
                        <div className="col-12 col-md-6">
                            <ReportTypeCard
                                title="Tax Declaration"
                                midText="Summarizes tax-related information, including tax amounts and declarations, helping you fulfill tax obligations and ensure compliance."
                                icon={<FontAwesomeIcon icon={faCalendar} /> }
                                textEnd="Last Opened"
                            />
                        </div>
                    </div>)
                }

{
                    type == 5 && (<div className="row g-2 mt-2">
                        <div className="col-12 col-md-6">
                            <ReportTypeCard
                                title="Supplier Movement"
                                midText="Track your supplier movement in your store"
                                icon={<FontAwesomeIcon icon={faCalendar} /> }
                                textEnd="Last Opened"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <ReportTypeCard
                                title="Supplier Debit Lifetime"
                                midText="Displays the cumulative debit transactions for each supplier over their entire lifetime, providing insights into payment history and outstanding balances."
                                icon={<FontAwesomeIcon icon={faCalendar} /> }
                                textEnd="Last Opened"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <ReportTypeCard
                                title="Purchase Order Details"
                                midText="Provides detailed information about individual purchase orders, including order dates, items ordered, quantities, and other relevant details."
                                icon={<FontAwesomeIcon icon={faCalendar} /> }
                                textEnd="Last Opened"
                            />
                        </div>
                    </div>)
                }
            </div>
        </Layout>
    )
}

export default Reports;