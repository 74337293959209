import React from 'react';
import Layout from '../../Layout/default';


function CashManagement() {
    return(
        <Layout>
            <div>Cash Management</div>
        </Layout>
    )
}

export default CashManagement;