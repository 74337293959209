import {React, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Layout/default";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSearch, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import CustomModal from "../../components/Modal/Confirmation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function User() {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const edit = () => {
        toast.info("You can edit now");
        setTimeout(() => {
            navigate('/company/add');
        }, 1000);
    };

    const deleteRow = () => {
        toast.error("Deleted successfully");
    }
    
    return(
        <Layout>
            <div className="container-fluid">

                <div className="row g-3 mt-2">
                    <div class="col-12 col-md-4">
                        <div class="input-group">
                            <span class="input-group-text bg-white cursor-pointer border-end-0"><FontAwesomeIcon className="cursor-pointer" icon={faSearch} /></span>
                            <input className="border border-start-0 p-2 form-control shadow-none" type="area" placeholder="Search" />
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                    </div>
                    
                    <div className="col-12 col-md-2 text-md-end">
                        <Link to="#" className="btn btn-primary">+ Add User</Link>
                    </div>
                </div>


                <div className="row g-2 mt-2">
                    <div className="bg-white border rounded p-3">

                        
                        <div class="table-responsive mt-2">
                            <table class="table table-bordered table-hover">
                                <thead class="">
                                    <tr>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th>Status</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Zahidaz</td>
                                        <td>zahidaz@.com</td>
                                        <td>Admin</td>
                                        <td>Active</td>
                                        <td className="text-center">
                                            <FontAwesomeIcon onClick={() => edit()} className="ms-2 text-info cursor-pointer" icon={faPen} />
                                            <FontAwesomeIcon onClick={() => deleteRow()} className="ms-2 text-danger cursor-pointer" icon={faTrashCan} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <CustomModal
                    showModal={showModal}
                    closeModal={closeModal}
                />
            </div>
            <ToastContainer />
        </Layout>
        
    )
}

export default User;