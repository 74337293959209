import React from 'react';

const ReportTypeCard = ({title, icon, midText, textEnd}) => {
    return(
        <div className='card h-100 bg-white px-3 py-2'>
            <div className="mb-1 d-flex justify-content-between align-items-center">
                <div className='d-flex'>
                    <div>{title}</div>
                </div>
            </div>
            <div>
                <p className="card-text mt-3">{midText}</p>

                <div className='d-flex'>
                    <div>{icon}</div>
                    <p className='mx-2'>{textEnd}</p>
                </div>
            </div>
        </div>
    )
}

export default ReportTypeCard;