import React from "react";
import Layout from "../../Layout/default";
import { Link } from "react-router-dom";

function PurchaseOrder() {
    return(
        <Layout>
            <div className="col-12 text-center py-5"> 
                <div>
                    <img src="https://platform.rewaatech.com/assets/images/empty-product.svg" />
                </div>

                <div className="py-4">
                    <div className="fw-bold">You don’t have any purchase orders</div>
                </div>

                <div>
                    <Link to="/stockcontrol/purchaseorder/create" className="btn btn-primary">+ New Purchase Order</Link>
                </div>
            </div>
        </Layout>
    )
}

export default PurchaseOrder;