import {React} from "react";
import Layout from "../../Layout/default";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSearch, faTrashCan } from "@fortawesome/free-solid-svg-icons";

function Orders() {

    return(
        <Layout>
            <div className="container">
                <div className="row g-2 mt-2">
                    <div className="bg-white border rounded p-3">
                        <div className="row g-3">
                            <div class="col-12 col-md-5">
                            <div class="input-group">
                                <span class="input-group-text bg-white cursor-pointer border-end-0"><FontAwesomeIcon className="cursor-pointer" icon={faSearch} /></span>
                                <input className="border border-start-0 p-2 form-control shadow-none" type="area" placeholder="Start typing to search" />
                            </div>
                            </div>

                        </div>
                        <div class="table-container mt-2">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th className="sticky-column">Order Id</th>
                                        <th>Order Number</th>
                                        <th>Customer Name</th>
                                        <th>Warehouse</th>
                                        <th>Payment Method</th>
                                        <th>Status</th>
                                        <th>Total Due</th>
                                        <th>Total</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1].map((item, index) => (
                                    <tr>
                                        <td className="sticky-column">1</td>
                                        <td>1234</td>
                                        <td>Zahidaz</td>
                                        <td>9 no</td>
                                        <td>Card</td>
                                        <td>Active</td>
                                        <td>0</td>
                                        <td>100</td>
                                        <td className="text-center">
                                            <FontAwesomeIcon className="ms-2 text-info cursor-pointer" icon={faPen} />
                                            <FontAwesomeIcon className="ms-2 text-danger cursor-pointer" icon={faTrashCan} />
                                        </td>
                                    </tr>))
                                }
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">...</a></li>
                                    <li class="page-item"><a class="page-link" href="#">10</a></li>
                                    <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Orders;