import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function CustomModal({showModal, closeModal}) {
  

  return (
    <div className="container mt-5">
      {showModal && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Deletion</h5>
                  <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <p>Do you really want to delete this?</p>
                </div>
                <div className="modal-footer d-block">
                  <div className='row g-0 m-0 p-0'>
                    <div className='col-6 pe-1'>
                      <button type="button" className="btn btn-success w-100" onClick={() => closeModal(0) }>
                        Cancel
                      </button>
                    </div>
                    <div className='col-6 ps-1'>
                      <button type="button" className="btn btn-danger w-100" onClick={() => closeModal(1) }>
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CustomModal;
