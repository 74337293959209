import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../App.css';
import { Link } from 'react-router-dom';

const DashboardStatusCard = ({text, textPrice, textEnd, linkArrow, percentage, icon, firstIcon, secondIcon, title, hintPopover, thisMonthTitle, thisMonthSubTitle, lastMonthTitle, lastMonthSubTitle, link, linkText, price}) => {
  return (
    <div className='card h-100 bg-white px-3 py-2'>
        <div className="mb-1 d-flex justify-content-between align-items-center">
            <div className='d-flex'>
                <div className='me-2'>{firstIcon}</div>
                <div>{title}</div>
            </div>
            <div>
                {secondIcon} {hintPopover}
            </div>
        </div>
        <div>
            {price} {percentage} {icon}
        </div>
        <div>
            <p className="mb-0 fw-bold">{lastMonthTitle}</p>
            <p className="card-text">{lastMonthSubTitle}</p>

            <h5 className="card-title fs-6">{thisMonthTitle}</h5>
            <p className="card-text">{thisMonthSubTitle}</p>
            <p>{textEnd}</p>
        </div>
        <div className='text-end'>
            <Link href={link} className="primary">{linkText} {linkArrow}</Link>
        </div>
    </div>
  )
}

export default DashboardStatusCard;