import {React} from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSearch, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Layout from "../../Layout/default";

function Customers() {
    return(
        <Layout>
            <div className="container">
                <div className="row g-2 mt-2">
                    <div className="bg-white border rounded p-3">
                        <div className="row g-3">
                            <div class="col-12 col-md-5">
                                <div class="input-group">
                                    <span class="input-group-text bg-white cursor-pointer border-end-0"><FontAwesomeIcon className="cursor-pointer" icon={faSearch} /></span>
                                    <input className="border border-start-0 p-2 form-control shadow-none" type="area" placeholder="Search" />
                                </div>
                            </div>
                        </div>
                        <div class="table-container mt-2">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th className="sticky-column">Customer Code</th>
                                        <th>Customer Name</th>
                                        <th>Customer Email</th>
                                        <th>Phone Number</th>
                                        <th>Total Paid</th>
                                        <th>Debit Amount</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    [1,1,1,1,1,1,1,1,1,1,1,1].map((item, index) => (
                                    <tr>
                                        <td className="sticky-column">1</td>
                                        <td>Zahidaz</td>
                                        <td>Z1000@z.com</td>
                                        <td>092765162</td>
                                        <td>100</td>
                                        <td>80</td>
                                        <td className="text-center">
                                            <FontAwesomeIcon className="ms-2 text-info cursor-pointer" icon={faPen} />
                                            <FontAwesomeIcon className="ms-2 text-danger cursor-pointer" icon={faTrashCan} />
                                        </td>
                                    </tr>))
                                }
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">...</a></li>
                                    <li class="page-item"><a class="page-link" href="#">10</a></li>
                                    <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="col-12 text-center py-5"> 
                        <div>
                            <img src="https://platform.rewaatech.com/assets/images/empty-product.svg" />
                        </div>
                        <div className="py-4">
                            <div>You haven't added any customer yet</div>
                        </div>
                        <div>
                            <Link to="/company/add" className="btn btn-primary">+ New Customer</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Customers;