import {React, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Layout/default";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faPen, faRightToBracket, faSearch, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import CustomModal from "../../components/Modal/Confirmation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Company() {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        toast.success("Company deleted successfully");
    };

    const backUp = () => {
        toast.success("Backup Successfully");
    };

    const edit = () => {
        navigate('/company/add');
        // toast.info("You can edit now");
        // setTimeout(() => {
        //     navigate('/company/add');
        // }, 1000);
    };

    const login = () => {
        toast.success("Login Successfully");
        setTimeout(() => {
            navigate('/');
        }, 1000);
    }

    
    return(
        <Layout>
            <div className="container-fluid">
                <div className="row g-3 mt-2">
                    <div class="col-12 col-md-4">
                        <div class="input-group">
                            <span class="input-group-text bg-white cursor-pointer border-end-0"><FontAwesomeIcon className="cursor-pointer" icon={faSearch} /></span>
                            <input className="border border-start-0 p-2 form-control shadow-none" type="area" placeholder="Search" />
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                    </div>
                    
                    <div className="col-12 col-md-2 text-md-end">
                        <Link to="/company/add" className="btn btn-primary">+ Add Company</Link>
                    </div>
                </div>
                <div className="row g-2 mt-2">
                    <div className="bg-white border rounded p-3">
                        
                        <div class="table-responsive mt-2">
                            <table class="table table-bordered table-hover">
                                <thead class="">
                                    <tr>
                                        <th>#</th>
                                        <th>Company Name</th>
                                        <th>Company Code</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Zahidaz</td>
                                        <td>Z1000</td>
                                        <td className="text-center">
                                            <FontAwesomeIcon onClick={() => login()} className="ms-2 text-primary cursor-pointer" icon={faRightToBracket} />
                                            <FontAwesomeIcon onClick={() => backUp()} className="ms-2 text-success cursor-pointer" icon={faCloudArrowUp} />
                                            <FontAwesomeIcon onClick={() => edit()} className="ms-2 text-info cursor-pointer" icon={faPen} />
                                            <FontAwesomeIcon onClick={() => openModal()} className="ms-2 text-danger cursor-pointer" icon={faTrashCan} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 text-center py-5"> 
                        <div>
                            <img src="https://platform.rewaatech.com/assets/images/empty-product.svg" />
                        </div>
                        <div className="py-4">
                            <div>You haven't added any comapny yet</div>
                        </div>
                        <div>
                            <Link to="/company/add" className="btn btn-primary">+ Add Company</Link>
                        </div>
                    </div>
                </div>
                <CustomModal
                    showModal={showModal}
                    closeModal={closeModal}
                />
            </div>
            <ToastContainer />
        </Layout>
        
    )
}

export default Company;