import {React, useState} from "react";
import Layout from "../Layout/default";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownLong, faArrowUpLong, faPen, faSearch, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import CustomModal from "../../components/Modal/Confirmation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Inventory() {

    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const edit = () => {
        toast.info("You can edit now");
        setTimeout(() => {
            navigate('/inventory/add');
        }, 1000);
    };

    return(
        <Layout>  
            <div className="container-fluid">
                <div className="row g-3 mt-2">
                    <div class="col-12 col-md-6">
                        <div class="input-group">
                            <span class="input-group-text bg-white cursor-pointer border-end-0"><FontAwesomeIcon className="cursor-pointer" icon={faSearch} /></span>
                            <input className="border border-start-0 p-2 form-control shadow-none" type="area" placeholder="Search" />
                        </div>
                    </div>

                    <div className="col-12 col-md-6 text-md-end">
                        <div className="d-flex float-end">
                            <button className="btn btn-primary inverse fw-600 me-2"><FontAwesomeIcon className="me-2" icon={faArrowUpLong} />Export</button>
                            <button className="btn btn-primary inverse fw-600 me-2"><FontAwesomeIcon className="me-2" icon={faArrowDownLong} />Import</button>
                            <Link to="/inventory/add" className="btn btn-primary">+ New Product</Link>
                        </div>
                    </div>
                </div>

                <div className="row g-2 mt-2">
                    <div className="bg-white border rounded p-3">
                        <div class="table-container mt-2">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th className="sticky-column">Product Name</th>
                                        <th>Category</th>
                                        <th>Supplier</th>
                                        <th>Product Type</th>
                                        <th>Tracked</th>
                                        <th>SKU</th>
                                        <th>Available Quantity</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        [1,1,1,1,1,1,1,1,1,1,1,1].map((item, index) => (<tr>
                                            <td className="sticky-column">Zahidaz Zahidaz Zahidaz Zahidaz</td>
                                            <td>Products</td>
                                            <td>2 no</td>
                                            <td>Physical</td>
                                            <td>Yes</td>
                                            <td>102</td>
                                            <td>100</td>
                                            <td className="text-center sticky-column">
                                                <FontAwesomeIcon onClick={() => edit()} className="ms-2 text-info cursor-pointer" icon={faPen} />
                                                <FontAwesomeIcon onClick={() => openModal()} className="ms-2 text-danger cursor-pointer" icon={faTrashCan} />
                                            </td> 
                                        </tr>))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">...</a></li>
                                    <li class="page-item"><a class="page-link" href="#">10</a></li>
                                    <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="col-12 text-center py-5"> 
                        <div>
                            <img src="https://platform.rewaatech.com/assets/images/empty-product.svg" />
                        </div>
                        <div className="py-4">
                            <div>You haven't added any products yet</div>
                        </div>
                        <div>
                            <Link to="/inventory/add" className="btn btn-primary">+ New Product</Link>
                        </div>
                    </div>
                </div>
                <CustomModal
                        showModal={showModal}
                        closeModal={closeModal}
                    />
                <ToastContainer />
            </div>
        </Layout>
    )
}

export default Inventory;